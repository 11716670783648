import 'leaflet/dist/leaflet.css'
import * as R from 'ramda'
import {node} from 'prop-types'
import React, {useRef} from 'react'

import {cardsListPropTypes} from 'helpers/propTypes'
import Description from 'components/UI/Description'
import Media from 'components/UI/Media'
import Title from 'components/UI/Title'

import useStyles from './styles'

const LocationCards = ({data, phoneText, faxText}) => {
  const classes = useStyles()

  const ref = useRef(null)
  const refCard = useRef(null)

  const renderCardsList = () =>
    R.map(card => {
      const {
        name,
        id,
        address: {address},
        latitude,
        longitude,
        photo,
        phone,
        fax,
      } = card

      const renderNumbers = (number, label) =>
        number && (
          <Description>
            <span className={classes.isBold}>{label}</span>
            <ul className={classes.numberList}>
              {R.map(
                e => (
                  <li className={classes.numberItem}>{e}</li>
                ),
                number,
              )}
            </ul>
          </Description>
        )

      return (
        // eslint-disable-next-line no-restricted-globals
        !isNaN(latitude) &&
        // eslint-disable-next-line no-restricted-globals
        !isNaN(longitude) && (
          <div key={id} ref={refCard} className={classes.cardFrontmatter}>
            <div className={classes.thumbnailImage}>
              <Media data={photo} />
            </div>
            <div className={classes.textBlock}>
              <div className={classes.title}>
                <Title variant="h2" type="subTitle">
                  {name}
                </Title>
              </div>
              <Description>{address}</Description>
              {renderNumbers(phone, phoneText)}
              {renderNumbers(fax, faxText)}
            </div>
          </div>
        )
      )
    }, data)

  return (
    <>
      <div className={classes.cardsList}>
        <div className={` ${classes.sideWrapper}`} ref={ref}>
          <div>{renderCardsList()}</div>
        </div>
      </div>
    </>
  )
}

LocationCards.propTypes = cardsListPropTypes
LocationCards.defaultProps = {
  data: node,
  faxText: '',
  phoneText: '',
}

export default LocationCards
