import * as R from 'ramda'
import {graphql} from 'gatsby'
import React from 'react'

import {contactUsPropTypes} from 'helpers/propTypes'
import {formRedirection, removeDuplicate} from 'helpers/utils'
import ContactUsSection from 'components/ContactUs/ContactUsSection'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import Title from 'components/UI/Title'

import useIsMobile from 'hooks/useIsMobile'

const ContactUs = ({pageContext, data, location}) => {
  const isMobile = useIsMobile()
  const {prefix} = pageContext
  const countryData = R.path(['contentfulCountry'], data)
  const {navLocale, technicalName} = countryData
  const {metaTitle, metaDescription, pageTitle, formId} = R.pathOr(
    null,
    ['contentfulContactUs'],
    data,
  )

  const {
    title,
    description,
    firstnamePlaceholder,
    lastnamePlaceholder,
    emailPlaceholder,
    phonePlaceholder,
    companyPlaceholder,
    messagePlaceholder,
    callToAction,
    icon,
    termsOfUseConsent,
    privacyPolicyConsent,
  } = R.pathOr(' ', ['contentfulForm'], data)

  const formData = R.pathOr(null, ['contentfulForm'], data)

  const siteURL = R.pathOr(null, ['site', 'siteMetadata', 'siteUrl'], data)
  const {faxLabel, phoneLabel} = R.pathOr(
    '',
    ['allContentfulLocations', 'edges', 0, 'node'],
    data,
  )
  const locations = R.pathOr(
    null,
    ['allContentfulLocations', 'edges', 0, 'node', 'locations'],
    data,
  )

  const mappedCountries = R.map(
    el => R.map(e => e.technicalName === technicalName && el, el.country),
    locations,
  )

  const countryLocations = removeDuplicate(
    R.pipe(
      R.flatten,
      R.filter(el => el !== false),
      R.filter(el => el !== null),
    )(mappedCountries),
  )

  const uniqueLocations = R.uniqBy(R.prop('id'), countryLocations)

  const {countryCode, salesforce} = R.pathOr(null, ['contentfulCountry'], data)

  const formRedirect = formRedirection(siteURL, countryCode, navLocale, 'lead')

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      {isMobile && (
        <Section hasPaddingTop hasPaddingBottom={false}>
          <div style={{marginTop: 16}}>
            <Title hat={pageTitle} isCentered hasMarginTop />
          </div>
        </Section>
      )}
      <ContactUsSection
        allToAction={callToAction}
        companyPlaceholder={companyPlaceholder}
        countryCode={countryCode}
        data={uniqueLocations}
        description={description}
        emailPlaceholder={emailPlaceholder}
        faxText={faxLabel}
        firstnamePlaceholder={firstnamePlaceholder}
        formData={formData}
        hubSpotCountry={salesforce}
        icon={icon}
        lastnamePlaceholder={lastnamePlaceholder}
        locale={navLocale}
        location={location}
        locationState={location.state}
        messagePlaceholder={messagePlaceholder}
        phonePlaceholder={phonePlaceholder}
        phoneText={phoneLabel}
        prefix={prefix}
        privacyPolicyConsent={privacyPolicyConsent}
        redirect={formRedirect}
        salesforceCountry={salesforce}
        siteURL={siteURL}
        technicalName={technicalName}
        termsOfUseConsent={termsOfUseConsent}
        title={title}
        websiteSource=""
        formId={formId}
        titleVariant="h1"
      />
    </>
  )
}

ContactUs.propTypes = contactUsPropTypes

ContactUs.defaultProps = {
  callToAction: '',
  companyPlaceholder: '',
  description: '',
  emailPlaceholder: '',
  faxLabel: ' ',
  firstnamePlaceholder: '',
  icon: '',
  lastnamePlaceholder: '',
  messagePlaceholder: '',
  metaDescription: '',
  metaTitle: '',
  phoneLabel: ' ',
  phonePlaceholder: '',
  title: '',
}

export default ContactUs

export const pageQuery = graphql`
  query templateContactUs(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    contentfulContactUs(
      node_locale: {eq: $nodeLocale}
      slug: {regex: $entityRegEx}
    ) {
      id
      name
      pageTitle
      metaTitle
      metaDescription {
        metaDescription
      }
      formId
    }
    allContentfulLocations(
      filter: {slug: {regex: $entityRegEx}, node_locale: {eq: $nodeLocale}}
    ) {
      edges {
        node {
          ...locationsFields
        }
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
      salesforce
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    contentfulForm(
      name: {regex: $entityRegEx}
      node_locale: {eq: $nodeLocale}
    ) {
      ...FormFields
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`
