import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({spacing, breakpoints, palette}) => ({
  sideWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 40,
  },
  cardsList: {
    display: 'flex',
    flex: '100%',
  },
  cardFrontmatter: {
    all: 'inherit',
    marginRight: spacing(5),
    marginBottom: spacing(5),
    textDecoration: 'none',
    width: '100%',
    color: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    '&:first-child': {
      marginTop: 0,
    },
    '& > div': {
      width: 250,
    },
    [breakpoints.down('sm')]: {
      margin: spacing(5, 'auto'),
      alignItems: 'center',
      width: 'fit-content',
      flexDirection: 'column',
    },
  },
  thumbnailImage: {
    maxWidth: '100%',
    [breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
    '& > div': {
      height: 180,
      width: 230,
      borderRadius: spacing(5, 0),
      transform: 'translateZ(0)',
      [breakpoints.down('sm')]: {
        width: 280,
        height: 140,
      },
    },
  },
  textBlock: {
    marginLeft: spacing(2.5),

    '& > p:first-of-type': {
      color: palette.text.grey,
    },
    [breakpoints.down('sm')]: {
      margiBottom: spacing(5),
      marginTop: spacing(2.5),
      marginLeft: spacing(0),
    },
  },
  title: {
    display: 'flex',
  },
  numberList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  numberItem: {
    listStyle: 'none',
  },
  isBold: {
    fontFamily: 'CamptonMedium',
    marginRight: spacing(1),
  },
}))

export default useStyles
